// import { createGlobalStyle } from 'styled-components';
// import reset from 'styled-reset-advanced';

// export default createGlobalStyle`
//   ${reset};

//   body {
//     font-family: 'Avenir Next', 'Helvetica Neue', 'Helvetica', sans-serif;
//     font-weight: 500;
//   }
// `;

import { css, Global } from '@emotion/core';
import reset from 'emotion-reset';
import React from 'react';
import ArgestaHeadline from '../../static/fonts/Argesta_Webfont/argestaheadline-regular-webfont.ttf';

const GlobalReset = () => (
  <Global
    styles={css`
      ${reset}
      @font-face {
        font-family: 'ArgestaHeadline';
        src: url(${ArgestaHeadline});
      }

      *,
      *::after,
      *::before {
        box-sizing: border-box;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
      }
    `}
  />
);

export default GlobalReset;
